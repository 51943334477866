import { type FC } from 'react';
import { authProvider } from 'providers/authProvider';

const LogoutButton: FC = () => {
  const logout = () => {
    authProvider.signOut();
    window.location.href = '/users/sign_in';
  };

  return (
    <div
      role="button"
      style={{ cursor: `pointer` }}
      tabIndex={0}
      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gs-brand lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
      onClick={logout}
      onKeyDown={logout}
    >
      ログアウト
    </div>
  );
};

export default LogoutButton;
